import { Link } from '@inertiajs/react';
import { PropsWithChildren } from 'react';

export default function GuestLayout({ children }: PropsWithChildren) {
  return (
    <div className='flex min-h-screen flex-col items-center bg-gray-100 pt-6 sm:justify-center sm:pt-0 dark:bg-gray-900'>
      <div className='mt-6 overflow-hidden bg-white px-6 py-4 shadow-md sm:w-[800px] lg:max-w-[1200px] sm:rounded-lg dark:bg-gray-800'>
        {children}
      </div>
    </div>
  );
}
